<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Payment Test"/>

    <div class="generalBorderlessBox">
        <div class="row">
            <div class="col-12 col-lg-6">
                <form id="payment-form">
                    <div v-if="errorMsg !== ''" id="error-message" class="text-danger mb-2">
                        <div class="alert alert-danger d-flex align-items-center" role="alert">
                            <table width="100%" border="0">
                                <tr>
                                    <td width="40px" class="align-top">
                                        <span><i class="fa-solid fa-circle-exclamation fa-2xl me-2"></i></span>
                                    </td>
                                    <td class="align-top">{{ errorMsg }}</td>
                                    <td width="35px" class="align-top text-end isLink" @click="errorMsg = ''">
                                        <i class="fa-solid fa-xmark fa-lg"></i>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div id="payment-element" class="mb-2">
                        <!-- Elements will create form elements here -->
                    </div>

                    <div class="row">
                        <div class="col-lg-8">
                            <div class="form-check py-2">
                                <input class="form-check-input" type="checkbox" v-model="allowSavePayment">
                                    Save my payment method for future use
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <button @click.prevent="submitPayment" class="btn btn-info text-white float-end" :class="allowSavePayment === true ? '' : 'disabled'">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        
    </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue'
import { useStore } from '@/stores/store'
import { useRoute, useRouter } from 'vue-router'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import funcs from '@/functions/function'


// const stripe = Stripe('pk_test_51QRqZVC0zBqE4xnOYmZeeGuU0bYe2kAjGKJgNtt5psaWaM6jRYvk9Y1HAAvD24Fon1Mxd5e5rAHqEAakZ87EJORp00nO8FiAw2')
const stripe = Stripe('pk_live_51QRqZVC0zBqE4xnO0jcJOlb1ZBwNN8TAu0EvQd3epV4f0JkEKjJo9l5KcOHkjuriMHZ2zpkPeqIKZ9JRhxLuTNmf00StLFb4ol')

/* let clientSecret = 'pi_3QaTeiC0zBqE4xnO0P6c2jEc_secret_dtJgCWTODVKgSJiO8dhnBcxwO' // Injected by EJS

const options = {
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {}
}

// Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in a previous step
const elements = stripe.elements(options) */

//---- new method
let clientSecret = ''
let options = null
let elements = null

export default {
    name: 'Payment',
    components: { TopNavigation, Alert },
    setup () {

        const alert = ref([])
        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        // const store = useStore()
        // const { getUserId } = useStore()

        // const stripe = ref(null)
        // const clientSecret = ref(null)
        // const stripeEl = ref(null)

        const showSubmit = ref(true)
        const allowSavePayment = ref(true)
        const errorMsg = ref('')


        const closeAlert = (index) => {
            alert.value.splice(index, 1)

        }

        const submitPayment = async () => {

            const successUrl = axios.defaults.baseURL + 'payment/stripe/recurring/success/' + clientSecret
            console.info('submit payment: if_required clientSecret', clientSecret)
            console.info('submit payment: successUrl', successUrl)

            /* errorMsg.value = ''
            let returnUrl = 'https://app.signon.my/auth/preference/payment3'
            if (window.location.hostname === 'localhost') {
                returnUrl = 'http://localhost:8080/auth/preference/payment3'
            }

            console.info('- - - submitPayment', 'returnUrl:', returnUrl)

            const { paymentIntent, error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: returnUrl, // Optional redirect URL : https://app.signon.my/completePayment
                }
            })

            console.info('paymentIntent:', paymentIntent, 'error:', error)

            if (error) {
                errorMsg.value = error.message
                console.info('Error:', error.message)
                
            } else {
                // Payment and saving the method were successful
                // alert('Payment successful! Payment method saved for future use.')
                console.info('PaymentIntent:', paymentIntent)

                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Payment successful! Payment method saved for future use."
                })
            } */

            /* const { paymentIntent, error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: successUrl, // Optional redirect URL : https://app.signon.my/completePayment
                }
            }) */

            const { paymentIntent, error } = await stripe.confirmPayment({
                elements,
                redirect: 'if_required' // Optional redirect URL : https://app.signon.my/completePayment
            })

            console.info('paymentIntent:', paymentIntent, 'error:', error)

            if (error) {
                errorMsg.value = error.message
                console.info('Error:', error.message)
                
            } else {
                // Payment and saving the method were successful
                // alert('Payment successful! Payment method saved for future use.')
                console.info('PaymentIntent:', paymentIntent)

                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Payment successful! Payment method saved for future use."
                })

                // redirect to backend when success
                window.location.href = successUrl

            }
        }

        onMounted(async () => {

            clientSecret = route.query.txnId
            console.info('plan onmounted: txnId', clientSecret)

            options = {
                clientSecret: clientSecret,
                // Fully customizable with appearance API.
                appearance: {}
            }

            // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in a previous step
            elements = stripe.elements(options)


            const paymentElement = elements.create('payment')
            paymentElement.mount('#payment-element')

        })

        return {
            route, router, alert, func, closeAlert, /* stripe, clientSecret, stripeEl, */ submitPayment, errorMsg, showSubmit, allowSavePayment
        }
    }

}
</script>
<style>
    
</style>